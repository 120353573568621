import React from 'react'

const PageHeader = ({ title, paragraph, className }) => {
  return (
    <section className={`page-header ${className && className}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-9">
            <div className="text-center blog_h1 section-padding">
              <h2 className="mb-10 color-font">{title}</h2>
              <p>{paragraph}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;